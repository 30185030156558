import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
  pagination,
} from "../../../store/administration/selectors/areas";

const Holidays = lazy(() => import("./Holidays"));

const mapStateToProps = (state) => ({
  isLoading: loading(state),
  holidays: MOCKUP,
  pagination: pagination(state),
});

export default connect(mapStateToProps)(Holidays);


const MOCKUP = [
  {
    "id": 1,
    "name": "Año Nuevo",
    "type": "Recurrente",
    "start_date": "01/01",
    "end_date": "01/01"
  },
  {
    "id": 2,
    "name": "Semana Santa",
    "type": "Recurrente",
    "start_date": "28/03",
    "end_date": "30/03"
  },
  {
    "id": 2,
    "name": "Día del trabajo",
    "type": "Recurrente",
    "start_date": "01/05",
    "end_date": "01/05"
  },
  {
    "id": 4,
    "name": "Día de la madre",
    "type": "Recurrente",
    "start_date": "10/05",
    "end_date": "10/05"
  },
  {
    "id": 5,
    "name": "Día del Padre",
    "type": "Recurrente",
    "start_date": "17/06",
    "end_date": "17/06"
  },
  {
    "id": 5,
    "name": "Semana agostina",
    "type": "Recurrente",
    "start_date": "03/08",
    "end_date": "05/08"
  },
  {
    "id": 6,
    "name": "Celebración del Divino Salvador del Mundo",
    "type": "Único",
    "start_date": "06/08/2024",
    "end_date": "06/08/2024"
  },
  {
    "id": 7,
    "name": "Día de la Independencia",
    "type": "Recurrente",
    "start_date": "15/09",
    "end_date": "15/09"
  },
  {
    "id": 8,
    "name": "Día de los difuntos",
    "type": "Recurrente",
    "start_date": "02/11",
    "end_date": "02/11"
  },
  {
    "id": 9,
    "name": "Día de Navidad",
    "type": "Recurrente",
    "start_date": "25/12",
    "end_date": "25/12"
  }
]