import React from "react";
import { Route, Switch } from "react-router-dom";

import Holidays from "../../../modules/Markings/Holidays";
import NewHolidays from "../../../modules/Markings/Holidays/new";
import ShowHolidays from "../../../modules/Markings/Holidays/show";

export default () => {
  return (
    <Switch>
      <Route exact path="/markings/holidays" component={Holidays} />
      <Route exact path="/markings/holidays/new" component={NewHolidays} />
      <Route exact path="/markings/holidays/:id" component={ShowHolidays} />
    </Switch>
  );
};