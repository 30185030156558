import React from "react";
import { Route, Switch } from "react-router-dom";

import Marked from "../../../modules/Markings/Marked";

export default () => {
  return (
    <Switch>
      <Route exact path="/markings/marked" component={Marked} />
    </Switch>
  );
};