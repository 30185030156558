import { lazy } from "react";
import { connect } from "react-redux";
import {
  loading,
  data,
} from "../../../../store/administration/selectors/areas";

const NewHoliday = lazy(() => import("./NewHoliday"));

const mapStateToProps = (state) => ({
  loading: loading(state),
  areas: data(state),
});

export default connect(mapStateToProps)(NewHoliday);
