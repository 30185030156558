import React from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

//Customs
import PrivateRoute from "../PrivateRoute";

//Selectors
import { isLogged } from "../../store/auth/selectors";

//* ROUTES *//
import Schedules from "./Schedules";
import Marked from "./Marked";
import Reports from "./Reports";
import Holidays from "./Holidays";

const LegalRoutes = ({ isLogged }) => {
  return (
    <Switch>
      <PrivateRoute
        path="/markings/schedules"
        component={Schedules}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/markings/marked"
        component={Marked}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/markings/reports"
        component={Reports}
        isAuthenticated={isLogged}
      />
      <PrivateRoute
        path="/markings/holidays"
        component={Holidays}
        isAuthenticated={isLogged}
      />
      <Redirect to="/" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLogged: isLogged(state),
});

export default connect(mapStateToProps)(LegalRoutes);
