import React from "react";
import { MdStreetview } from "react-icons/md";
import { BiHomeAlt } from "react-icons/bi";
import { BsFilePerson } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import { GiHistogram } from "react-icons/gi";
import { BsGraphUpArrow } from "react-icons/bs";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { BsPersonVideo2 } from "react-icons/bs";
import { BsPersonVideo3 } from "react-icons/bs";
import { MdOutlineSecurity } from "react-icons/md";
import { TbClockHour9 } from "react-icons/tb";
import { TbClockPlay } from "react-icons/tb";


const iconSize = "1.2rem";

/*
  NOTA: Sí agrega más items al menú principal y aunque se agregen a modulos en bakend.
  es necesario que tambien incluya su nuevo menú a rutesPrivates
*/

export const routesPrivates = [
  { module_id: 1, path: "/administration" },
  { module_id: 2, path: "/employees" },
  { module_id: 3, path: "/payroll" },
  { module_id: 4, path: "/deductions" },
  { module_id: 5, path: "/benefits" },
  { module_id: 6, path: "/legal" },
  { module_id: 7, path: "/personnel-actions" },
  { module_id: 8, path: "/vacations" },
  { module_id: 9, path: "/security" },
  { module_id: 10, path: "/schedule" },
  { module_id: 11, path: "/markings" },
]

export default [
  {
    name: "Administración",
    path: "/administration",
    icon: <BiHomeAlt size={iconSize} />,
    submodules: [
      {
        name: "Países",
        path: "/administration/countries",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Empresas",
        path: "/administration/companies",
        icon: <MdStreetview size={iconSize} />,
      },
      // {
      //   name: "Roles",
      //   path: "/administration/roles",
      //   icon: <MdStreetview size={iconSize} />,
      // },
      {
        name: "Conceptos Categorías",
        path: "/administration/concept-categories",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Conceptos Tipos",
        path: "/administration/concept-types",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Areas/Sub Areas",
        path: "/administration/areas",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Ubicaciones",
        path: "/administration/ubications",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Puestos",
        path: "/administration/positions",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Requisitos",
        path: "/administration/requirements",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Centros de Costos",
        path: "/administration/costcenters",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Documentos",
        path: "/administration/documents",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Entidades Financieras",
        path: "/administration/financial-entities",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Linea de Negocios",
        path: "/administration/line-business",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Parametros de Sistema",
        path: "/administration/settings",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Control de Horarios",
        path: "/administration/schedule-control",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
  {
    name: "Empleados",
    path: "/employees",
    icon: <BsFilePerson size={iconSize} />,
    submodules: [
      {
        name: "Empleados",
        path: "/employees/employees",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Reportes",
        path: "/employees/reports",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
  {
    name: "Nómina",
    path: "/payroll",
    icon: <VscGraph size={iconSize} />,
    submodules: [
      {
        name: "Admin. Nóminas",
        path: "/payroll/administration",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Nómina-Conceptos",
        path: "/payroll/concepts",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Historial",
        path: "/payroll/history",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Reportes",
        path: "/payroll/reports",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Boleta de pago",
        path: "/payroll/voucher",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
  {
    name: "Deducciones",
    path: "/deductions",
    icon: <GiHistogram size={iconSize} />,
    submodules: [
      {
        name: "Admin. Deducciones",
        path: "/deductions/administration",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Histórico",
        path: "/deductions/historical",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Cuadre financiero",
        path: "/deductions/financial-square",
        icon: <MdStreetview size={iconSize} />,
      },
      // {
      //   name: "Reportes",
      //   path: "/deductions/reports",
      //   icon: <MdStreetview size={iconSize} />,
      // },
    ],
  },
  {
    name: "Beneficios",
    path: "/benefits",
    icon: <BsGraphUpArrow size={iconSize} />,
    submodules: [
      {
        name: "Admin. Beneficios",
        path: "/benefits/administration",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Histórico",
        path: "/benefits/historical",
        icon: <MdStreetview size={iconSize} />,
      },
      // {
      //   name: "Reportes",
      //   path: "/benefits/reports",
      //   icon: <MdStreetview size={iconSize} />,
      // },
    ],
  },
  {
    name: "Legales",
    path: "/legal",
    icon: <HiOutlineClipboardDocument size={iconSize} />,
    submodules: [
      // {
      //   name: "ISSS",
      //   path: "/legal/isss",
      //   icon: <MdStreetview size={iconSize} />,
      // },
      // {
      //   name: "AFP",
      //   path: "/legal/afp",
      //   icon: <MdStreetview size={iconSize} />,
      // },
      {
        name: "SPU",
        path: "/legal/spu",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "ISR Mensual",
        path: "/legal/isr",
        icon: <MdStreetview size={iconSize} />,
      },
      // {
      //   name: "F-910",
      //   path: "/legal/f910",
      //   icon: <MdStreetview size={iconSize} />,
      // },
      {
        name: "ISR F910",
        path: "/legal/f910",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
  {
    name: "Acciones de personal",
    path: "/personnel-actions",
    icon: <BsPersonVideo2 size={iconSize} />,
    submodules: [
      {
        name: "Administración",
        path: "/personnel-actions/administration",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Histórico",
        path: "/personnel-actions/histories",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Creación",
        path: "/personnel-actions/create",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Aprobación",
        path: "/personnel-actions/approval",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
  {
    name: "Vacaciones",
    path: "/vacations",
    icon: <BsPersonVideo3 size={iconSize} />,
    submodules: [
      {
        name: "Periodo actual",
        path: "/vacations/current-period",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Goce de vacaciones",
        path: "/vacations/enjoy-vacations",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
  {
    name: "Seguridad",
    path: "/security",
    icon: <MdOutlineSecurity size={iconSize} />,
    submodules: [
      {
        name: "Usuarios",
        path: "/security/users",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
  {
    name: "Horarios",
    path: "/schedule",
    icon: <TbClockHour9 size={iconSize} />,
    submodules: [
      {
        name: "Administracion",
        path: "/schedule/administration",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Dashboard",
        path: "/schedule/dashboard",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
  {
    name: "Horarios",
    path: "/schedule",
    icon: <MdStreetview size={iconSize} />,
    submodules: [
      {
        name: "Administracion",
        path: "/schedule/administration",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Dashboard",
        path: "/schedule/dashboard",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
  {
    name: "Marcaciones",
    path: "/markings",
    icon: <TbClockPlay size={iconSize} />,
    submodules: [
      {
        name: "Horarios",
        path: "/markings/schedules",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Marcaciones",
        path: "/markings/marked",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Reportes",
        path: "/markings/reports",
        icon: <MdStreetview size={iconSize} />,
      },
      {
        name: "Festivos",
        path: "/markings/holidays",
        icon: <MdStreetview size={iconSize} />,
      },
    ],
  },
];
