import React from "react";
import { Route, Switch } from "react-router-dom";

import Schedules from "../../../modules/Markings/Schedules";

export default () => {
  return (
    <Switch>
      <Route exact path="/markings/schedules" component={Schedules} />
    </Switch>
  );
};