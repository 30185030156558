import { lazy } from "react";
import { connect } from "react-redux";
// import {
//   loading,
//   data,
//   pagination,
// } from "../../../store/administration/selectors/areas";

const Schedules = lazy(() => import("./Schedules"));

const mapStateToProps = (state) => ({
  // isLoading: loading(state),
  // holidays: MOCKUP,
  // pagination: pagination(state),
});

export default connect(mapStateToProps)(Schedules);
